<template>
<div :key="contractKey">
  <div class="div_title" >
    <context :context=cate />
  </div>
  <div>
    <div v-if="((this.api.getCookies('login') === 'true' ) && this.api.getCookies('comnum') !=='' && this.api.getCookies('comnum') !=='undefined')" >
      <possess :possessList="possessList" :isEmpty="isEmpty" @click="click" :key="possessKey" :page="this.api.getCookies('page')"/>
      <div v-if="contractType === 'search'">
        <div class="div_title" >
          <span>
            <select class="select-contract" style="width:90px;text-align:center;float:none;margin-left:5px;" v-model="contractType" @change="change({type:'contractType', value:'regist'})" > 
              <option value="search">주문조회</option>
              <option value="regist">주문등록</option>
            </select>
            <label v-if="this.totalCnt !== 0">{{this.totalCnt}}건</label>
          </span>
          <span>
            <span style="margin-top:-6px;float:right;" ref="search"><Button label="조회" v-on:click="click({type:'search'})" raised size="small" ></Button></span>
            <select class="select-contract" style="max-width:70px;" v-model="item" ref="contract_select_box"> 
              <option value = "all">전체</option>
              <option v-for="(item, index) in itemList" :value="item.NAME" v-bind:key="index">{{item.NAME}}</option>
            </select>
            <select class="select-contract" v-model="cnt"> 
              <option value="10">10개씩</option>
              <option value="50">50개씩</option>
              <option value="100">100개씩</option>
              <option value="200">200개씩</option>
              <option value="500">500개씩</option>
            </select>
            <label ref="lbl_detail_symbol" class="contract-detail-search" @click="detailSearch()" style="margin-right: 10px;margin-left: 2px;">∨</label>
            <label class="contract-detail-search link" @click="detailSearch()">조건추가</label>
          </span>
        </div>
        <div ref="groupDetailGroup" class="card main" style="margin-top: 20px; background-color: aliceblue; display:none">
          <table style="width:100%;">
            <tr style="width:100%;">
              <td style="max-width: 100px; min-width: 100px;">
                <datepicker v-model="pickDateFrom" />
              </td>
              <td style="text-align: center;">&#126;</td>
              <td style="max-width: 100px; min-width: 100px;">
                <datepicker v-model="pickDateTo" />
              </td>
              <td style="width:100%">
                <span style="float:right;" ref="searchDetail"><Button label="조회" v-on:click="click({type:'searchDetail'})" raised size="small" ></Button></span>
                <input ref="conditionValue" type="text" style="max-width:50px; padding: 5px; float:right; margin-right:10px;" placeholder="검색어" v-on:keyup.enter="click({type:'searchDetail'})">
                <select class="select-contract" style="margin-top: 0px;max-width: 70px;" v-model="conditionDetail" @change="change({type:'condition'})"> 
                  <option value = "">전체</option>
                  <option value = "name">이름</option>
                  <option value = "clearance">통관부호</option>
                  <option value = "invoice">송장번호</option>
                  <option value = "phone">폰번호</option>
                </select>
              </td>
            </tr>
            <tr>
              <td colspan="3">
                <Tag severity="danger" class="small-btn" value="오늘" style="cursor:pointer;" @click="click({type:'range', value:0})"/>
                <Tag severity="danger" class="small-btn" value="이번달" style="cursor:pointer;" @click="click({type:'range', value:1})"/>
                <Tag severity="danger" class="small-btn" value="한달" style="cursor:pointer;" @click="click({type:'range', value:30})"/>
              </td>
            </tr>
          </table>
        </div>
        <div style="margin-top: 10px;" >
          <div v-if="orderList.length === 0" class="card main no-result"> 
            조회 내역이 없습니다.
          </div>
          <div v-else class="card main">
            <DataTable v-model:expandedRows="expandedRows" v-model:selection="selectedOrder" :value="orderList" dataKey="IDX" stripedRows @rowExpand="onRowExpand">
              <Column expander style="max-width:10px;" />
              <Column selectionMode="multiple" style="max-width:10px;"></Column>
              <Column field="IDX" header="IDX"></Column>
              <Column header="등록일">
                <template #body="slotProps">
                  {{ this.api.getDateByFormat(slotProps.data.ORDER_DATE, 'YYYY.MM.DD') }}
                </template>
              </Column>
              <Column field="NAME" header="상품명"></Column>
              <Column header="상태" style="min-width:90px;">
                <template #body="slotProps">
                  {{ this.api.getOrderStatus(Number(slotProps.data.STATUS)) }} 
                </template>
              </Column>
              <template #expansion="slotProps" >
                <contractc :uid="slotProps.data.UID" :deliveryKrCompany="slotProps.data.DELIVERY_KR_COMPANY" :status="this.api.getOrderStatus(Number(slotProps.data.STATUS))" :amount="slotProps.data.AMOUNT" :deliveryPrice2="slotProps.data.DELIVERY_PRICE2" :country="slotProps.data.COUNTRY" :invoiceNumber="slotProps.data.INVOICE_NUMBER"  :idx="slotProps.data.IDX" :name="slotProps.data.NAME" :notice="slotProps.data.NOTICE" :key="contractcKey"/>
              </template>
            </DataTable>
            <div style="text-align: center; margin: 20px;">
              <label @click="paging('prev')" class="link">
                이전
              </label>
              <span style="margin:20px">{{page}}</span>
              <label @click="paging('next')" class="link">
                다음
              </label>
              <label @click="excel()" class="link" style="float: right; color: green;">Excel</label>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <contract_order @selectChange="change" :itemList="itemList"/>
      </div>
    </div>
    <div v-else-if="((this.api.getCookies('login') === 'true' || this.api.getCookies('login') === true) && (this.api.getCookies('comnum') ==='' || this.api.getCookies('comnum') ==='undefined'))" >
      <div class="card main" style="font-weight:normal">
        <p class="agree">1. GEEK9와 계약된 업체만 가입/로그인이 가능합니다.</p>
        <p class="agree">2. 가입문의는 <a href="https://geek9.kr/qna" target="_blank">게시판</a>이나 <a href='tel:010-8235-0642' >유선</a>으로 연락주시면 됩니다</p>
        <p class="agree">3. GEEK9 소개글 보러가기 - <a href="https://sosoceo.tistory.com/category/%ED%95%B4%EC%99%B8" target="_blank">블로그</a></p>
        <p class="agree">4. 명함받기 - <a class="link" @click="click({type:'download'})">클릭</a></p>
      </div>
      <Tabs :value="mIdx">
        <TabList>
          <Tab value=0 style="color:blue;">기업 담당자 로그인</Tab>
          <Tab value=1 style="color:blue;">기업 담당자 가입</Tab>
          <Tab value=2 style="color:coral;">포워더 로그인</Tab>
        </TabList>
        <TabPanels class="card main">
          <TabPanel value=0>
            <div class="center_title" >
              <FloatLabel class="flex-item input-margin" >
                <InputText id="comNumLog"  v-model="comNumLog" v-on:keyup.enter="click({type:'log'})" style="width:100%;"/>
                <label for="comNumLog" >사업자번호</label>
              </FloatLabel>
              <Button class="flex-item" label="로그인하기" @click="click({type:'log'})" />
            </div>
          </TabPanel>
          <TabPanel value=1>
            <div class="center_title">
              <FloatLabel class="flex-item input-margin" >
                <InputText id="comNumReg" v-model="comNumReg" v-on:keyup.enter="click({type:'reg'})" style="width:100%;"/>
                <label for="comNumReg" >사업자번호 (숫자만)</label>
              </FloatLabel>
              <FloatLabel class="flex-item input-margin" >
                <InputText id="phoneReg" v-model="phoneReg" v-on:keyup.enter="click({type:'reg'})" style="width:100%;"/>
                <label for="phoneReg" >연락처 (숫자만)</label>
              </FloatLabel>
              <Button class="flex-item" label="가입하기" @click="click({type:'reg'})" />
            </div>
          </TabPanel>
          <TabPanel value=2>
            <br>
            <div class="center_title" >
              <Button class="flex-item" label="로그인하기" @click="click({type:'forwarder'})" />
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
    <login v-else @login="login" /> 
  </div>
  <div v-if="orderedMap.size !== 0">
    <div class="div_title">
      <span style="color: #ff7f50;">합산</span> 
      <p v-if="this.api.getCookies('comnum') === '7278801252'" style="margin-right:10px;float:right;">기준환율 : {{ this.innomaxHkdCurrency }} HKD ({{ this.api.getDateByFormat(new Date(), 'YYYY/MM') }})</p>
    </div>
    <div class="card main" style="margin-top: 10px;margin-bottom: 40px;width:100%;"> 
      <DataTable :value="Array.from(orderedMap)" stripedRows >
        <Column :header="this.getHeader(index)" v-for="(index, ) in [0, 1, 2, 3]" :key="index" style="font-style:italic;min-width:100px;width:25%;">
          <template #body="slotProps" >
            <div style="font-weight: normal;">
              <label v-if="index === 0">{{ slotProps.data[index] }}</label>
              <label v-if="index === 1">{{ slotProps.data[index].amount }}</label>
              <label v-if="index === 2">{{ Number(slotProps.data[1].delivery).toLocaleString() }}</label>
              <label v-if="index === 3">{{ (Number(Math.ceil(slotProps.data[1].delivery / slotProps.data[1].amount / 1)) * 1).toLocaleString() }}</label>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
  <div class="ad-container">
    <Adsense data-ad-client="ca-pub-6699354447815429" data-ad-slot="8230887469" data-ad-format="horizontal" data-full-width-responsive="true" />
  </div>
  <Modal v-model='isShow' :close='closeModal' style="z-index: 99999999999;">
    <div class="modal">
      <div class="div_title">
        <span style="color: #ff7f50;">재고이력</span> 
        <Button @click="closeModal()" label="닫기" raised size="small"></Button>
      </div>
      <div class="card main" style="text-align: left;">
        <p v-if="contentList.length === 0" class="point"><span >이력이 없습니다.</span></p>
        <p v-else class="point" v-for="(content, index) in contentList" :key="index">
          <span>[ {{this.api.getDateByFormat(content.REG_DATE, 'YYYY.MM.DD')}} ] &nbsp; {{content.CONTENT}} </span>
        </p>
      </div>
      <br>
      <div style="text-align: center;">
        <label @click="contentPaging('prev')" class="link">
          이전
        </label>
        <span style="margin:20px">{{ contentPage}}</span>
        <label @click="contentPaging('next')" class="link">
          다음
        </label>
      </div>
    </div>
  </Modal>
</div>
</template>

<script>
// import { useMeta } from 'vue-meta'
import contractc from '@/components/content/contract_content.vue'
import contract_order from '@/components/contract/contract_order.vue'
import datepicker from 'vue3-datepicker'
import { ref, inject } from 'vue'
import possess from '@/components/common/possess.vue'
import context from '@/components/common/Context.vue'
import ssr from './components/common/ssr'
import login from '@/components/common/Login.vue'
import router from '@/router'

const xlsx = require('xlsx')

export default {
  emits: ["setCookies", "setMenu", "login"],
  components: {
    contractc, datepicker, contract_order, possess, context, login
  },  
  mounted() {
    this.addJsonLd()
    this.emitter.on('contractReload', this.reload)
    this.api.setPage('contract')
    this.$emit("setMenu", {page:'contract'})
    console.log(this.api.getCookies('page'))
    if(this.api.getCookies('login') === 'true') {
      const comName = this.api.getCookies('comName')
      if( !(comName === '' || comName === null) ) this.emitter.emit("setValue", {comName:comName})
      this.setItems({comnum:this.api.getCookies('comnum'), useYn:true, isEmpty:true})
    }
    let firstDay = new Date(this.pickDateFrom.getFullYear(), this.pickDateFrom.getMonth(), 1);
    this.pickDateFrom = firstDay
    window.scrollTo(0, 0)
  },
  unmounted() {
    this.emitter.off('contractReload', this.reload)
  },
  setup() {
    const setMeta = inject('setMeta')
    const title = 'GEEK9 > 기업고객'
    const keywords = '기업, 물류대행, 직구,해외직구,구매대행,배송대행,배대지,해외운송,이사,geek9,3PL,풀필먼트,주문,기업,고객사,WMS'
    const description = 'GEEK9에서 제공하는 기업 고객 전용 페이지 - 주문/조회/재고'
    const geek9Url = ssr.GEEK9_URL + 'contract'
    setMeta({
      title: title,
      keywords: keywords,
      description: description,
      'og:title': title,
      'og:description': description,
      'og:image': 'https://geek9.kr/img/logo_big.e0186dc8.jpg',
      'og:url': geek9Url,
      'canonical': geek9Url
    })
    const addJsonLd = () => {
      let flag = true
      document.querySelectorAll('script').forEach(el => {
        if (el.getAttribute('type') === 'application/ld+json' ) {
          flag = false
          return
        }
      })
      if(flag === true) {
        const script = document.createElement('script')
        script.setAttribute('type', 'application/ld+json')
        script.textContent = JSON.stringify(
          {
            "@context": "https://schema.org",
            "@graph": [
              ssr.getWebPage(geek9Url, title, ssr.GEEK9_URL, description),
            ]
          }
        )
        document.head.appendChild(script)
      }
    }
    const isShow = ref(false)
    async function showModal () {
      isShow.value = true;
    }
    function closeModal () {
      isShow.value = false
    }
    const cate = ref([
      { label: '기업고객', route: '/contract' }
    ])
    function onRowExpand (event) {
      expandedRows.value = {[event.data.IDX]:true}
    }
    const collapseAll = () => {
      expandedRows.value = null
      selectedOrder.value = null
    }
    const selectedOrder = ref()
    const expandedRows = ref({})

    return {
      closeModal, onRowExpand, showModal, isShow, 
      cate, selectedOrder, expandedRows, collapseAll, addJsonLd
    }
  },
  data() { 
    return { 
      isEmpty:false,
      comNumLog:'',
      comNumReg:'',
      phoneReg:'',
      mIdx:'0',
      item: 'all',
      cnt:"10",
      totalCnt:0,
      contractType:'search',
      contentPage:1,
      page:1,
      contractKey:0,
      possessKey:0,
      conditionDetail:'',
      comNum:'',
      comName:'',
      innomaxHkdCurrency:0,
      contractcKey:-1,
      pickDateFrom:ref(new Date()),
      pickDateTo:ref(new Date()),
      itemList: Array(),
      contentList: ref([]),
      possessList: ref([]),
      orderList: Array(),
      orderedMap: new Map(),
      param:{}
    }
  },
  methods: {
    login() {
      this.$emit('login')
    },
    getHeader(key) {
      if(key === 0) return '상품명'
      else if(key === 1) return '수량(개)'
      else if(key === 2) return '배송비(원)'
      else if(key === 3) return '단가(원)'
    },
    async contentPaging(type) {
      if(type === 'prev') {
        if(this.contentPage === 1) {
          alert('첫 페이지입니다.')
          return
        } else this.contentPage -= 1
      } else this.contentPage += 1
      await this.api.axiosGql('SELECT_ORDER_HISTORY', {type:'SELECT_ORDER_HISTORY', id:this.api.getCookies('comnum'), page:this.contentPage})
      .then( async (res) => {
        let json = res.data
        if(json.length === 0) {
          this.contentPage -= 1
          alert('마지막 페이지입니다.')
        } else {
          this.contentList = res.data
          this.showModal()
        }
      }).catch( (err) => {
        console.log(err)
        if(type === 'prev') this.contentPage += 1
        else this.contentPage -= 1
      })
    },    
    async excel() {
      let list = Array()
      if(this.selectedOrder === undefined || this.selectedOrder === null || this.selectedOrder.length === 0) alert('주문을 선택해주세요')
      else {
        this.emitter.emit('dialog', {flag:true})
        let execelList = Array()
        for(let i=0; i<this.selectedOrder.length; i++) {
          list.push(Number(this.selectedOrder[i].IDX))
        }
        for(let i=0; i<this.orderList.length; i++) {
          if(list.length !== 0 && list.indexOf(this.orderList[i].IDX) === -1) continue
          const item = this.orderList[i]
          await this.api.axiosGql('SELECT_ORDER_INFO_BY_UID', {type:'SELECT_ORDER_INFO_BY_UID', ID: "", UID:item.UID})
          .then( async (res) => {
              let result = res.data
              execelList.push({
                IDX:item.IDX, 
                주문일:item.ORDER_DATE,
                상품명:item.NAME,
                진행상황:this.api.getOrderStatus(Number(item.STATUS)),
                택배:item.DELIVERY_KR_COMPANY,
                송장번호:item.INVOICE_NUMBER,
                배송비:item.DELIVERY_PRICE2,
                수량:item.AMOUNT,
                국가:item.COUNTRY,
                수취인:result.ORDER_NAME,
                폰번호:result.PHONE,
                통관부호:result.CLEARANCE,
                도로명주소:result.ADDR_ROAD,
                상세주소:result.ADDR_DETAIL
              })
          }).catch( (err) => {
            console.log(err)
            this.$notify('다시 시도해주세요')
            alert('다시 시도해주세요')
          })
        }
        const workBook = xlsx.utils.book_new()
        const workSheet = xlsx.utils.json_to_sheet(execelList)
        xlsx.utils.book_append_sheet(workBook, workSheet, 'geek9.kr')
        xlsx.writeFile(workBook, this.api.getCookies('comname') + '.xlsx')
        this.emitter.emit('dialog', {flag:false})
      }
    },
    change(param) {
      this.orderList = Array()
      const type = param.type
      if(type === 'condition') {
        if(this.conditionDetail === '') this.$refs.conditionValue.value = ''
      } else if(type === 'contractType') {
        this.orderedMap.clear()
        this.contractType = param.value
        this.totalCnt = 0
      }
    },
    detailSearch() {
      if(this.$refs.groupDetailGroup.style.display === 'none' ) {
        this.$refs.lbl_detail_symbol.innerHTML = '∧'
        this.$refs.groupDetailGroup.style.display = ''
        this.$refs.search.style.display = 'none'
      } else {
        this.$refs.lbl_detail_symbol.innerHTML = '∨'
        this.$refs.groupDetailGroup.style.display = 'none'
        this.$refs.search.style.display = ''
      } 
    },
    paging(type) {
      this.collapseAll()
      if(type === 'prev') {
        if(this.page === 1) {
          this.$notify('첫 페이지입니다.')
          return
        } else this.page -= 1
      } else this.page += 1
      this.param.page =  this.page
      this.companyItemSearch()
    },    
    async setItems(param) {
      this.api.axiosGql('SELECT_ITEM', {type:'SELECT_ITEM', num:param.comnum, useYn:param.useYn, isEmpty:param.isEmpty})
      .then( (res) => {
        const json = res.data
        if(json.length === 0) {
          this.page -= 1
        } else this.itemList = res.data
      })
    },
    async click(param) {
      const type = param.type
      const id = this.api.getCookies('id')
      const mail = this.api.getCookies('mail')
      
      if(type === 'searchDetail') {
        this.collapseAll()
        this.page             = 1
        let dateF             = new Date(this.pickDateFrom)
        let dateT             = new Date(this.pickDateTo)
        let pickDateFrom      = new Date(dateF.getTime() - (dateF.getTimezoneOffset() * 60000)).toISOString()
        let pickDateTo        = new Date(dateT.getTime() - (dateT.getTimezoneOffset() * 60000)).toISOString()
        const condition       = this.conditionDetail
        const conditionValue  = String(this.$refs.conditionValue.value).replace(/ /gi, '') 
        const startDate       = Number(new Date(pickDateFrom).toISOString().slice(0,10).replace(/-/g,""))
        const endDate         = Number(new Date(pickDateTo).toISOString().slice(0,10).replace(/-/g,""))
        
        if(startDate > endDate) this.$notify('날짜를 확인해 주세요.')
        else if(condition === '' && conditionValue !== '') this.$notify('조건을 선택해 주세요.')
        else if(condition !== '' && conditionValue === '') this.$notify('검색어를 입력해 주세요.')
        else {
          this.param = {type:'SELECT_COMPANY_ORDER', startDate:startDate, endDate:endDate, condition:condition, conditionValue:conditionValue, page:this.page, cnt:Number(this.cnt)}
          this.companyItemSearch()
        }
      } else if(type === 'search') {
        this.collapseAll()
        this.page = 1
        this.param = {type:'SELECT_COMPANY_ORDER', page:this.page, cnt:Number(this.cnt)}
        this.companyItemSearch()
      } else if(type === 'forwarder') {
        router.push({ name: type })
      } else if(type === 'reg') {
        const comNum = this.comNumReg
        const phone = this.phoneReg
        if(comNum === '' || phone === '' || comNum === undefined || phone === undefined) {
          alert('누락된 값이 있습니다.')
          this.$notify('누락된 값이 있습니다.')
        } else {
          if(confirm('가입 이후에는 업체 변경이 불가합니다!\n업체 당당자로 등록하시겠습니까?') === true) {
            await this.api.axiosGql('SELECT_COMPANY', {type:'SELECT_COMPANY', num:comNum})
            .then( async (res) => {
              const json = res.data 
              if(json === null || json === undefined || json.length === 0) {
                alert('입력하신 사업자번호로 등록된 업체가 없습니다.\n문의 게시판에 사업자등록 요청을 해주세요')
                this.$notify('입력하신 사업자번호로 등록된 업체가 없습니다.\n문의 게시판에 사업자등록 요청을 해주세요')
              } else {
                await this.api.axiosGql('SELECT_USER', {type:'SELECT_USER', id:id, mail: mail, service: comNum})
                .then( async (res) => { 
                  const json = res.data
                  if(json === null || json === undefined || json.length === 0) {
                    await this.api.axiosGql('UPSERT_USER', {type:'UPSERT_USER', name: this.api.getCookies('nickname'), id: id, mail: mail, service: comNum, phone: phone, userType:1})
                    .then((res1) => {
                      if(res1.data === null) {
                        alert('이미 기업 담당자님으로 가입되어 있는 폰번호입니다.')
                        this.$notify('이미 기업 담당자님으로 가입되어 있는 폰번호입니다.')
                      } else {
                        this.emitter.emit('confirm', {message: '가입이 완료되었습니다', header:'가입', icon:'pi pi-info-circle'})
                        this.api.setCookies('comnum', comNum)
                      }
                    })
                  } else {
                    alert('이미 기업 담당자로 등록된 계정입니다.')
                    this.$notify('이미 기업 담당자로 등록된 계정입니다.')
                  }
                })
              }
            })
          }
        }
      } else if(type === 'log') {
        const comNum = this.comNumLog
        if(comNum === undefined || comNum === '') {
          alert('누락된 값이 있습니다.')
          this.$notify('누락된 값이 있습니다.')
        } else {
          await this.api.axiosGql('SELECT_USER', {type: 'SELECT_USER', id: id, mail: mail, service: comNum})
          .then( async (res) => {
            const json = res.data
            if(json === null || json === undefined || json.length === 0) {
              alert('등록된 기업 담당자님이 아닙니다.')
              this.$notify('등록된 기업 담당자님이 아닙니다.')
            } else {
              await this.api.axiosGql('SELECT_COMPANY', {type:'SELECT_COMPANY', num:comNum})
              .then( async (res1) => {
                const json = res1.data 
                if(json === null || json === undefined || json.length === 0) {
                  alert('입력하신 사업자번호로 등록된 기업이 없습니다.\n문의 게시판에 사업자등록 요청을 해주세요')
                  this.$notify('입력하신 사업자번호로 등록된 기업이 없습니다.\n문의 게시판에 사업자등록 요청을 해주세요')
                } else {
                  this.$notify('담당자 로그인 성공')
                  this.api.setCookies('comnum', comNum)
                  this.comName = json[0].NAME
                  this.comNum = json[0].NUM
                  this.contractKey++
                  this.emitter.emit("setValue", {comName:this.comName})
                  this.setItems({comnum:comNum, useYn:true, isEmpty:true})
                }
              })
            }
          })
        }
      } else if(type === 'range') {
        const val = param.value
        this.pickDateTo = ref(new Date())
        if(val === 0) {
          this.pickDateFrom = ref(new Date())
        } else if(val === 1) {
          let firstDay = new Date(this.pickDateTo.getFullYear(), this.pickDateTo.getMonth(), val);
          this.pickDateFrom = firstDay
        } else if(val === 30) {
          let before30days = new Date(this.pickDateTo.getFullYear(), this.pickDateTo.getMonth(), this.pickDateTo.getDate() - val);
          this.pickDateFrom = before30days
        }
      } else if(type === 'possess') {
        this.isEmpty = param.isEmpty
        await this.api.axiosGql('SELECT_ITEM', {type:'SELECT_ITEM', num:this.api.getCookies('comnum'), isEmpty:this.isEmpty})
        .then( async (res) => {
          const json = res.data
          this.possessList = json
          this.possessKey++
        })
      } else if(type === 'history') {
        await this.api.axiosGql('SELECT_ORDER_HISTORY', {type:'SELECT_ORDER_HISTORY', id:this.api.getCookies('comnum'), page:1})
        .then( async (res) => {
          this.contentList = res.data
          this.showModal()
        })
      } else if(type === 'del') {
        let list = Array()
        if(this.selectedOrder.length === 0) {
          alert('삭제할 항목을 선택해주세요.')
        } else {
          if(confirm('선택한 항목을 삭제하시겠습니까?') === true) {
            for(let i=0; i<this.selectedOrder.length; i++) {
              list.push(this.selectedOrder[i].IDX)
            }
            await this.api.axiosGql('DELETE_CHECKED_ORDER', {type:'DELETE_CHECKED_ORDER', list:list})
            .then( async (res) => {
              console.log(res)
              alert('삭제되었습니다.')
              this.companyItemSearch()
            })
          }
        }
      } else if(type === 'download') {
        const link = document.createElement('a')
        link.href = './assets/geek9.jpg'
        link.setAttribute('download', 'geek9.jpg')
        document.body.appendChild(link)
        link.click()
      }
    },
    async companyItemSearch() {
      this.emitter.emit('dialog', {flag:true})
      const selectedItem = this.$refs.contract_select_box.value
      let list = Array()
      if(selectedItem === 'all') {
        this.itemList.forEach((item) => {
          list.push(item.NAME)
        })
      } else list.push(selectedItem)
      
      if(list.length === 0) {
        alert('등록된 상품이 없습니다.\nGEEK9측에 문의해주세요!')
      } else {
        this.param.list = list
        await this.api.axiosGql('SELECT_COMPANY_ORDER', this.param)    
        .then( async (res) => {
          const origin = res.data
          if(origin === null || origin === 'null' || origin === undefined || origin.length === 0) {
            alert('데이터가 없습니다.')
            if(this.page === 1) {
              this.orderList = Array()
              this.totalCnt = 0
              this.orderedMap.clear()
            }
            this.page -= 1
          } else {
            this.orderList = origin
            this.totalCnt = origin.length 
            await this.sum()
            if(this.api.getCookies('comnum') === '7278801252') {
              await this.api.axiosGql('SELECT_NOTICE2', {type:'SELECT_NOTICE2', priority:Number(this.api.getDateByFormat(new Date(), 'YYYYMM')), noticeType: 'innomax', useYn:true})
              .then( async (result) => {
                if(result.data !== null && result.data !== undefined) {
                  this.innomaxHkdCurrency = result.data[0].CONTENT
                }
              }).catch( (err) => {
                console.log(err)
              })
            } 
          }
        })
      }
      this.emitter.emit('dialog', {flag:false})
    },
    async sum() {
      this.orderedMap.clear()
      this.orderList.forEach((item) => {
        const itemName = item.NAME
        const amount = Number(item.AMOUNT)
        const delivery = Number(item.DELIVERY_PRICE2)
        const status = Number(item.STATUS)

        if(this.orderedMap.has(itemName)) {
          let json = this.orderedMap.get(itemName)
          json.amount += amount
          json.delivery += delivery
        } else {
          if(status !== -2 && status !== -1) {
            let json = {
              amount:amount,
              delivery:delivery
            }
            this.orderedMap.set(itemName, json)
          }
        }
      })
    },
    reload(flag) {
      if(flag === 'dashboard') {
        this.totalCnt = 0
        this.innomaxHkdCurrency = 0
        this.itemList = Array()
        this.possessList = ref([])
        this.orderList = Array()
        this.orderedMap = new Map()

        this.contractKey++
        this.$emit("setMenu", {page:'contract'})
        this.contractcKey++
      }
    }
  }
}
</script>
<style>
.contract-detail-search {
  float: right;
  font-size: 10px;
  color:blue;
  vertical-align: middle;
  margin-top: 3px;
}
.v3dp__input_wrapper > input {
  padding-top:5px;
  padding-bottom:5px; 
  max-width:100px;
  text-align: center;
}
.small-btn {
  width:30%;
  font-size:11px;
  margin-left: 2px;
  margin-right: 2px;
}
#table-sum tr:nth-child(even){
  background-color: aliceblue;
}
#table-sum tr {
  height:30px;
}
.button2 {
  background-color: lightsalmon;  
  color: white;
  padding: 5px;
  font-weight: bold;
  border-radius: 9px;
  border: 0px;
  min-width: 80px;
  width:100%;
  margin:5px;
  cursor: pointer;
} 
.center_title {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px; /* Optional: set a max width */
  margin: 0 auto; /* Center the container horizontally */
}
.flex-item {
  width: 100%;
  margin-bottom: 20px; /* Optional: add spacing between items */
}
.flex-item:last-child {
  margin-bottom:20; /* Remove margin from the last item */
}
</style>
