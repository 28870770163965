<template>
  <select class="select-contract" style="width:90px;text-align:center;float:none;margin-left:5px;" @change="changed({type:'contractType', value:'search'})"> 
    <option value="search">주문조회</option>
    <option value="regist" selected>주문등록</option>
  </select>
  <label v-if="this.totalCnt !== 0">{{this.totalCnt}}건</label>
  <span class="status_cnt">
    <input id="chk_file" type="checkbox" style="max-width: 10px;" name="orderType" @click="checkbox('file')" checked value="1"/>
    <label for="chk_file" style="margin-right: 5px;">파일로등록</label>
    <input id="chk_fill" type="checkbox" style="max-width: 10px;" name="orderType" @click="checkbox('fill')" value="2"/>
    <label for="chk_fill" style="">직접입력</label>
  </span>
  <div ref="groupFile" class="card main" style="margin-top:10px;background-color: aliceblue;vertical-align: middle;">
    <label class="button" type="button" for="inputFile" style="max-width: 200px;float:left; ">파일등록</label>
    <label class="button" type="button" style="max-width: 200px;float:left;margin-left:10px;" v-if="this.api.getCookies('page') === 'admin'" @click="click({type:'googleSheet'})">시트등록</label>
    <input type="file" id="inputFile" ref="inputFile" @change="readFile" style="max-width: 0px;margin-bottom: 5px;" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet">
    <label class="link" @click="click({type:'download'})" style="margin-right: 5px;float:right;margin-top: 5px;">등록양식 다운로드</label>
  </div>
  <div ref="groupFill" class="card main container_no_login" style="margin-top:10px;display:none;">
    <cdc :itemList="itemList" @setOrder="setOrder" />
  </div>
  <div ref="list" v-if="orderList.length === 0" class="card main no-result">
    주문을 추가해주세요
  </div>
  <div v-else id="groupList" class="card main">
    <DataTable v-model:expandedRows="expandedRows" :value="indexOrderList" dataKey="index" stripedRows @rowExpand="onRowExpand" >
      <template #header>
      </template>
      <Column expander />
      <Column header="상품명">
        <template #body="slotProps">
          {{ slotProps.data.item[0] }}
        </template>
      </Column>
      <Column header="수량">
        <template #body="slotProps">
          {{ slotProps.data.item[1] }}
        </template>
      </Column>
      <Column header="주문자">
        <template #body="slotProps">
          {{ slotProps.data.item[2] }}
        </template>
      </Column>
      <Column header="연락처">
        <template #body="slotProps">
          {{ slotProps.data.item[3] }}
        </template>
      </Column>
      <template #expansion="slotProps" >
        <coc class="content" :index="slotProps.data.index" :itemList="itemList" :itemName=slotProps.data.item[0] :amount=Number(slotProps.data.item[1]) :orderName=slotProps.data.item[2] :phone=slotProps.data.item[3] :clearance=slotProps.data.item[4] :zipCode=String(slotProps.data.item[5]) :addrRoad=slotProps.data.item[6] :addrDetail=slotProps.data.item[7] :content=slotProps.data.item[8] />
      </template>
    </DataTable>
    <br>
    <Button @click="click({type:'reg'})" label="등록" raised size="small" />
  </div>
  
  <Dialog v-model:visible="progressVisible" modal header="등록 중..." :style="{ width: '50vw' }" :breakpoints="{ '1199px': '75vw', '575px': '90vw' }">
    <ProgressBar :value="progressedRate"> {{ progressedCnt }} / {{ progressTotalCnt }} </ProgressBar>
  </Dialog>
</template>

<script>
import coc from '@/components/contract/item.vue'
import cdc from '@/components/contract/contract_dorder.vue'
import { ref } from 'vue'
import moment from 'moment'

const xlsx = require('xlsx')

export default {
  emits: ["selectChange"],
  setup() {
    let progressVisible = ref(false)
    let progressTotalCnt = ref(0)
    let progressedCnt = ref(0)
    let progressedRate = ref(0)
    let indexOrderList = ref([])
    const expandedRows = ref({})
    function onRowExpand (event) {
      expandedRows.value = {[event.data.index]:true}
    }
    const expandAll = () => {
      expandedRows.value = indexOrderList.value.reduce((acc, p) => (acc[p.index] = true) && acc, {})
    }
    const progressModal = (flag) => {
      progressedCnt.value = 0
      progressTotalCnt.value = 0
      progressedRate.value = 0
      progressVisible.value = flag
    }
    const progress = () => {
      progressedCnt.value++
      progressedRate.value = Number(progressedCnt.value / progressTotalCnt.value * 100)
    }
    return { 
      onRowExpand, 
      expandAll,
      progressModal,
      progress,
      indexOrderList,
      expandedRows,
      progressTotalCnt,
      progressedCnt,
      progressedRate,
      progressVisible
    }
  },
  mounted() {
  },
  components: {
    coc, 
    cdc
  },  
  props: {
    itemList: ref([]),
  },
  data() { 
    return { 
      orderList:Array(),
      totalCnt:0,
      isChecked:true
    } 
  },
  methods: {
    checkbox(type) {
      let chkFill = document.getElementById('chk_fill')
      let chkFile = document.getElementById('chk_file')
      if(type === 'file' && chkFile.checked) {
        chkFile.checked = true
        chkFill.checked = false
        this.orderList = Array()
        if(this.$refs.list !== null) this.$refs.list.innerHTML = '파일을 선택해주세요'
      } else if(type === 'file' && !chkFile.checked) {
        chkFile.checked = true
        chkFill.checked = false
        if(this.$refs.list !== null) this.$refs.list.innerHTML = '파일을 선택해주세요'
      } else if(type === 'fill' && chkFill.checked) {
        this.orderList = Array()
        this.totalCnt = 0
        chkFill.checked = true
        chkFile.checked = false
        if(this.$refs.list !== null) this.$refs.list.innerHTML = '주문을 추가해주세요'
      } else if(type === 'fill' && !chkFill.checked) {
        this.orderList = Array()
        this.totalCnt = 0
        chkFill.checked = true
        chkFile.checked = false
        if(this.$refs.list !== null) this.$refs.list.innerHTML = '주문을 추가해주세요'
      }
      
      if(chkFile.checked) {
        this.$refs.groupFile.style.display = ''
        this.$refs.groupFill.style.display = 'none'
        this.emitter.emit('dorder')
      } else {
        this.$refs.groupFile.style.display = 'none'
        this.$refs.groupFill.style.display = ''
      }
    },
    readFile(event) {
      let orders = {}
      const reader = new FileReader()
      const file = event.target.files[0]

      if(this.itemList.length === 0 ) alert('등록 가능한 상품이 없습니다.\nGEEK9에 문의해주시기 바랍니다.')
      else {
        reader.onload = async (e) => {
          event.target.value=''
          let data = e.target.result
          data = new Uint8Array(data)
          let excelFile = xlsx.read(data, { type: "array" })
          
          excelFile.SheetNames.forEach(function(sheetName) {
            const roa = xlsx.utils.sheet_to_json(excelFile.Sheets[sheetName], { header: 1 } )
            if (roa.length) orders[sheetName] = roa
          })
          let keys = Object.keys(orders)
          if(keys.length === 0) {
            alert('엑셀 파일에 주문 내역이 없습니다')
            this.$notify('엑셀 파일에 주문 내역이 없습니다')
            event.target.value=''
          } else {
            const key = keys[0]
            this.orderList = orders[key]
            this.orderList.shift()
            this.indexOrderList = []
            
            for(let i = 0; i < this.orderList.length; i++) {
              this.indexOrderList.push({index:i, item:this.orderList[i]})
            }
            this.setOrder(undefined)
          }
        }
        reader.readAsArrayBuffer(file)
      }
    },
    async setOrder(param) {
      this.totalCnt = 0
      let finalItemList = []
      if(param !== undefined) {
        this.orderList = param
      }
      if(this.itemList === undefined || this.itemList.length === 0) {
        await this.api.axiosGql('SELECT_ITEM', {type:'SELECT_ITEM', num:this.api.getCookies('comnum')})
        .then( async (res) => {
          const json = res.data
          if(json.length !== 0) finalItemList = json
          else {
            this.$notify('다시 시도해주세요.')
            alert('다시 시도해주세요.')
            this.changed({type:'contractType', value:'search'})
            return
          }
        })
      } else finalItemList = this.itemList
      for(let i = 0; i < this.orderList.length; i++) {
        this.totalCnt++
        const order = this.orderList[i]
        console.log(order)
        order[0] = String(order[0]).trim()
        order[2] = String(order[2]).replace(/ /g,'')
        order[3] = String(order[3]).replace(/[^0-9]/g,'')
        order[4] = String(order[4]).replace(/ /g,'')
        order[6] = String(order[6]).trim()

        const amount = String(order[1])
        const name = order[2]
        const phone = order[3]
        const clearance = order[4]
        const zipCode = String(order[5])
        const addrRoad = order[6]
        if(amount === name && name === clearance) {
          // this.orderList.splice(i, 1)
          this.orderList.splice(i, this.indexOrderList.length-1)
          this.indexOrderList.splice(i, this.indexOrderList.length-1)
          this.totalCnt--
          alert('주문 내용이 잘못되었습니다.')
          return
        } else {
          if(zipCode === undefined || zipCode === '' || zipCode === 'undefined') {
            this.notValid( (i+2), '우편번호가 누락')
            return
          } else if(zipCode.length === 4) order[5] = String( '0' + order[5])
          
          if(amount === undefined || amount === '' || amount === 'undefined') {
            this.notValid( (i+2), '수량이 누락')
            return
          } else if(name === undefined || name === '' || name === 'undefined') {
            this.notValid( (i+2), '수취인명이 누락')
            return
          } else if(phone === undefined || phone === '' || phone === 'undefined') {
            this.notValid( (i+2), '폰번호가 누락')
            return
          } else if(clearance === undefined || clearance === '' || clearance === 'undefined') {
            this.notValid( (i+2), '통관부호가 누락')
            return
          } else if(addrRoad === undefined || addrRoad === '' || addrRoad === 'undefined') {
            this.notValid( (i+2), '주소가 누락')
            return
          }
          const result = finalItemList.find((item) => item.NAME === order[0])
          if(result === undefined) {
            this.notValid( (i+2), '상품명이 잘못')
            alert(this.api.getCookies('comname') + '에서 등록 가능한 상품은 아래와 같습니다.\n' + finalItemList.map((item) => item.NAME).join(', '))
            return
          }
        }
      }
      this.indexOrderList = []
      for(let i = 0; i < this.orderList.length; i++) {
        this.indexOrderList.push({index:i, item:this.orderList[i]})
      }
      // this.orderList =  Array()
    },
    notValid(idx, msg) {
      alert( idx + '행의 ' + msg + ' 되었습니다.')
      this.changed({type:'contractType', value:'regist'})
      this.totalCnt = 0    
    },
    async click(param) {
      const type = param.type
      if(type === 'googleSheet') {
        this.emitter.emit('dialog', {flag:true})
        this.api.axiosGql(type, {type:type})
        .then( async (res) => {
          if(res.data === undefined || res.data.length === 0) alert('주문이 없습니다')
          else {
            this.indexOrderList = res.data
            this.orderList = Array()
            for(let i = 0; i < this.indexOrderList.length; i++) {
              this.orderList.push(this.indexOrderList[i].item)
            }
            console.log()
            this.setOrder(undefined)
          }
        })
        .catch((err) => {
          console.log(err)
          alert('다시 시도해주세요')
        })
        .finally( ()=> {
          this.emitter.emit('dialog', {flag:false})
        })
      } else if(type === 'download') {
        const link = document.createElement('a')
        link.href = '/doc/geek9_form.xlsx'
        link.setAttribute('download', 'geek9_form.xlsx')
        document.body.appendChild(link)
        link.click()
      } else if(type === 'checkbox') this.isChecked = !this.isChecked
      else if(type === 'reg') {
        await this.expandAll()
        await new Promise((resolve) => setTimeout(resolve, 1000))
        if(confirm('등록하시겠습니까?') === true) {
          const bindedContentList = document.querySelectorAll('.content')
          if(bindedContentList.length === 0) {
            alert('주문 신청서를 먼저 등록해주세요.')
            this.$notify('주문 신청서를 먼저 등록해주세요.')
          } else {
            const id   = this.api.getCookies('id')
            let mail = this.api.getCookies('mail')
            
            if(id) {
              // this.emitter.emit('dialog', {flag:true, title:'등록중입니다.'})
              this.progressModal(true)
              let param  = []
              let orderArray = new Array()
              this.progressTotalCnt = bindedContentList.length
              for(let i = 0; i < bindedContentList.length; i++) {
                let json = {}
                const content = bindedContentList[i]
                const inputList = content.getElementsByClassName('normalInput')
                json.itemName   = inputList[0].value
                json.amount     = inputList[1].value
                json.orderName  = inputList[2].value
                json.phone      = inputList[3].value
                json.clearance  = inputList[4].value
                json.zipCode    = inputList[5].value
                json.addrRoad   = inputList[6].value
                json.addrDetail = inputList[7].value
                json.brand      = inputList[8].value
                json.country    = inputList[9].value
                json.originPrice= inputList[10].value
                json.sellPrice  = inputList[11].value
                json.url        = inputList[12].value
                json.imgUrl     = inputList[13].value
                json.content    = inputList[14].value
  
                param.push(json)
              }
  
              for(let j = 0; j < param.length; j++) {
                const order = param[j]
                const itemName    = order.itemName
                const imgUrl      = order.imgUrl
                const amount      = order.amount
                const url         = order.url
                const orderName   = order.orderName
                const brand       = order.brand
                const phone       = order.phone
                const clearance   = String(order.clearance).substring(0, 13)
                const zipCode     = String(order.zipCode)
                const addrRoad    = order.addrRoad
                const originPrice = order.originPrice
                const sellPrice   = order.sellPrice
                const content     = order.content
                const country     = order.country
  
                let addrDetail = order.addrDetail
  
                if(addrDetail === undefined) addrDetail = ''
                // if(brand === '에스더포뮬러') mail = 'seohyun.lee@estherformula.co.kr'

                let orderInfoJson = {type:'INSERT_USER', mail: mail, addrZip: zipCode, addrRoad: addrRoad, addrDetail: addrDetail, name: orderName, clearance: clearance, phone: phone, id:id}
                await this.api.axiosGql('INSERT_USER', orderInfoJson)
                .then( async (res) => {
                  if(res.data === '') console.log('Already Existed User : ' + orderName)
                  else if(res.data.NAME === orderName ) console.log('INSERT_USER SUCCESS : ' + orderName)
                  else {
                    this.$notify('다시 시도해주세요.')  
                    // this.emitter.emit('dialog', {flag:false})
                    this.progressModal(false)
                    alert(orderName + ' 님의 정보가 유효하지 않습니다.')  
                    return
                  }
                  orderInfoJson.type = 'INSERT_ORDER_INFO'
                  await this.api.axiosGql('INSERT_ORDER_INFO', orderInfoJson)
                  .then( async (res) => {
                    const uid = res.data.UID
                    if(uid !== null ) {
                      
                      let orderJson = {}
  
                      orderJson.URL             = url
                      orderJson.NAME            = itemName
                      orderJson.BRAND           = brand
                      orderJson.IMG_URL         = imgUrl
                      orderJson.AMOUNT          = Number(amount)
                      orderJson.ORIGIN_PRICE    = originPrice
                      orderJson.SELL_PRICE      = Number(sellPrice)
                      orderJson.ORDER_NUMBER    = ''
                      orderJson.TRACKING_NUMBER = ''
                      orderJson.NOTICE          = content
                      orderJson.COUNTRY         = country
                      orderJson.ORDER_TYPE      = '배송대행'
                      orderJson.UID             = uid
                      orderJson.ID              = id
                      orderJson.REG_DATE        = moment(new Date()).format('YYYYMMDDhhmm')
                      orderJson.ORDER_DATE      = moment(new Date()).format('YYYYMMDD')
                      
                      orderArray.push(JSON.stringify(orderJson))
  
                    } else {
                      this.$notify('다시 시도해주세요.')  
                      // this.emitter.emit('dialog', {flag:false})
                      this.progressModal(false)
                      alert('다시 시도해주세요.')  
                    }
                  })
                  .catch( (err) => {
                    this.$notify('다시 시도해주세요.')
                    // this.emitter.emit('dialog', {flag:false})
                    this.progressModal(false)
                    alert('다시 시도해주세요.')  
                    console.log(err)
                  })
                })
                .catch( (err) => {
                  this.$notify('다시 시도해주세요.')
                  // this.emitter.emit('dialog', {flag:false})
                  this.progressModal(false)
                  alert('다시 시도해주세요.')  
                  console.log(err)
                }) 
                this.progress()
              }
              const formData = new FormData()
              formData.append("list", orderArray)
              formData.append('slack', '기업고객')
              formData.append('type', 'INSERT_ORDER_LIST')
  
              await this.api.axiosGql('INSERT_ORDER_LIST', formData)
              .then( async (res) => {
                if(res.data !== undefined ) {
                  this.$notify('등록되었습니다. 주문조회를 하시면 진행상황을 확인하실 수 있습니다!!')
                  alert('등록되었습니다. 주문조회를 하시면 진행상황을 확인하실 수 있습니다!!')
                  // this.emitter.emit('dialog', {flag:false})
                  this.progressModal(false)
                  this.changed({type:'contractType', value:'search'})
                } else {
                  this.$notify('다시 시도해주세요.')  
                  // this.emitter.emit('dialog', {flag:false})
                  this.progressModal(false)
                  alert('다시 시도해주세요.')  
                }
              })
              .catch( (err) => {
                this.$notify('다시 시도해주세요.')
                // this.emitter.emit('dialog', {flag:false})
                this.progressModal(false)
                alert('다시 시도해주세요.')  
                console.log(err)
              })
            } else {
              this.$notify('로그인 후 다시 시도해주세요.')
              // this.emitter.emit('dialog', {flag:false})
              this.progressModal(false)
              alert('로그인 후 다시 시도해주세요.')  
            }
          }
        }
      } 
    },
    changed(param) {
      const type = param.type
      if(type === 'contractType') {
        this.orderList = Array()
        this.totalCnt = 0
        this.$emit('selectChange', param)
      }
    }
  }
}
</script>